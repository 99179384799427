import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const APIKeyPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://docs.google.com/document/d/15aKmr_z7v9Bkg_pJlmR5sSeO2b0J2WJGeinrwE2IJvk/edit?usp=sharing')
        }, 100)
        

        }, []),

        <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>Get Your API Key</>}
      
    />
  </Layout>
);

export default APIKeyPage;
